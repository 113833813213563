import { computed, Injectable, signal } from '@angular/core';
import { debounceTime, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private readonly BREAKPOINTS = {
    xs: 0,
    sm: 576, //mobile Breakpoint
    md: 768,
    lg: 992, //tablet breakpoint
    xl: 1200,
    xxl: 1400,
  } as const;

  // signal for current screen width
  private screenWidth = signal<number>(window.innerWidth);

  // computed signals of current mode
  public isMobile = computed(() => this.screenWidth() < this.BREAKPOINTS.sm);
  public isTablet = computed(
    () => this.screenWidth() >= this.BREAKPOINTS.sm && this.screenWidth() < this.BREAKPOINTS.lg,
  );
  public isDesktop = computed(() => this.screenWidth() >= this.BREAKPOINTS.lg);

  // Computed Signal of current breakpoint
  public currentBreakpoint = computed(() => {
    const width = this.screenWidth();
    if (width < this.BREAKPOINTS.sm) return 'xs';
    if (width < this.BREAKPOINTS.md) return 'sm';
    if (width < this.BREAKPOINTS.lg) return 'md';
    if (width < this.BREAKPOINTS.xl) return 'lg';
    if (width < this.BREAKPOINTS.xxl) return 'xl';
    return 'xxl';
  });

  constructor() {
    //create the window resize listener with a debounce time to avoid event bombing

    fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe(() => {
        this.screenWidth.set(window.innerWidth);
      });
  }

  /**
   * Checks if the screen width meets or exceeds the specified breakpoint value.
   *
   * @param {keyof typeof this.BREAKPOINTS} breakpoint - The key representing the breakpoint to compare against.
   * @return {boolean} Returns true if the screen width is greater than or equal to the specified breakpoint value, otherwise false.
   */
  public isMinWidth(breakpoint: keyof typeof this.BREAKPOINTS): boolean {
    return this.screenWidth() >= this.BREAKPOINTS[breakpoint];
  }
}
